import React from "react"
import { verify } from "../services/verify"
import Layout from "../components/global/layout"
import Container from "../components/container/container"

const verifyPage = ({ component: Component, location, ...rest }) => {
    
    const verifyData = {
        otp: "111",
        email: "text@example.com"
    }
    verify(verifyData).then((res) => {
        // console.log("verifyData Response: ", res)
    })

    return (
        <Layout>
            <Container>
                <h1>Verify Page</h1>
            </Container>
        </Layout>
    )
}
export default verifyPage

