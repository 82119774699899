export const verify = async (data) => {

    try {
        const response = await fetch(`/api/verify`, {
            method: `POST`,
            headers: {
                "content-type": "application/json",
            },
            body: JSON.stringify(data),
        }).then((res) => res.json())

        // console.log("service/ verify() Response: ", response)
        return response;

    } catch (error) {
        return error
    }
    
}